import React from 'react';
import Link from 'next/link'
import { ReplyIcon } from '@heroicons/react/outline';

export default function FourZeroFourPage() {
    return (
        <div>
            <div className='p-10'>
                <div>
                    <p className="mb-5 text-3xl leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl">404</p>
                </div>
                <p>The page you are looking for does not exist.</p>
                <Link href='/' className="cursor-pointer" passHref>
                    <button
                        className="
                              inline-flex 
                              items-center 
                              justify-center  
                              rounded-sm
                              px-4 py-3
                              shadow-sm 
                              text-lg
                              font-medium 
                              text-white 
                              bg-gray-800
                              hover:bg-gradient-to-r hover:from-sky-500 hover:to-sky-600 
                              focus:outline-none 
                              focus:ring-2 
                              focus:ring-offset-2 
                              focus:ring-sky-500"
                    >
                        <ReplyIcon className="h-5 w-5 mr-2" /> Home
                    </button>
                </Link>
            </div>
        </div>
    );
}
